import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import HomePage from './components/HomePage';
import ErrorReport from './components/ErrorReport';
import ThanksSupport from './components/ThanksSupport';
import 'bulma/css/bulma.css'

const Routing = () => {
  return (
    <Router>
      <Switch>
        <Route path="/start" exact component={App} />
        <Route path="/config" exact component={App} />
        <Route path="/support" exact component={ErrorReport} />
        <Route path="/thanks-support" exact component={ThanksSupport} />
        <Route path="/" component={HomePage} />
      </Switch>
    </Router>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Routing />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
